import { render, staticRenderFns } from "./scene5.vue?vue&type=template&id=3e6d4f45&scoped=true&"
import script from "./scene5.vue?vue&type=script&lang=ts&"
export * from "./scene5.vue?vue&type=script&lang=ts&"
import style0 from "./scene5.vue?vue&type=style&index=0&id=3e6d4f45&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3e6d4f45",
  null
  
)

export default component.exports