















import {Component, Prop, Vue} from "vue-property-decorator";
import {Roles} from "@/Interfaces/sessionInterface";
import NextSceneButton from "@/components/sceneItems/NextSceneButton.vue";

@Component({
  components: {NextSceneButton}
})
export default class scene1 extends Vue {
  @Prop({required:true})role!: Roles


  startKlopGeluid(event:Event){
    this.$store.dispatch('setSceneData',{kloppen:Date.now()})
  }

  startSintVerhaal(){
    this.$store.dispatch('setSceneData',{sintVerhaal:Date.now()})
  }

  sounds:HTMLAudioElement[] = [new Audio(require('@/assets/knock.wav')),new Audio(require('@/assets/audio/black_screen_sint.mp3'))]
  unwatchArray:(()=>void)[]=[()=>{}]

  mounted(){
    if(this.role=== "screen") {
      this.sounds[1].onended =()=>{
        this.$store.dispatch("setSceneData",{showNext:true})
      }
      this.unwatchArray.push(
          this.$store.watch((state, getters) => getters.getSceneData.kloppen, value => {
          this.sounds[0].play()
      }))
      this.unwatchArray.push(
          this.$store.watch((state, getters) => getters.getSceneData.sintVerhaal,value =>{
            this.sounds[1].play()

          }
      ))
    }
  }

  beforeDestroy(){
    if(this.role=='screen'){
      this.unwatchArray.forEach(value => value())
      this.sounds.forEach(value => value.pause())
    }
  }

}
