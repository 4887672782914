





















import {Component, Prop, Vue} from "vue-property-decorator";
import {Roles, User} from "@/Interfaces/sessionInterface";
import NextSceneButton from "@/components/sceneItems/NextSceneButton.vue";

@Component({
  components: {NextSceneButton}
})
export default class TussenStand extends Vue {
  @Prop() role!: Roles

  get position(){
    if(this.role ==='user'){
      let position=-1
      this.orderedList.forEach((value, index) => {
        console.log(value[0])
        console.log(this.$store.state.userId)
        if(value[0]===this.$store.state.userId){
          position =index+1
        }
      })
      return position
    }
    return;
  }

  get orderedList(){
    let list =Object.entries<User>(this.$store.state.sessionData.users)
    return list.sort((a, b) => {
      return b[1].gameData.correctAnswers-a[1].gameData.correctAnswers
    })
  }

}
