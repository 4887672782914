






























import {Component, Prop, Vue} from "vue-property-decorator";
import {Roles} from "@/Interfaces/sessionInterface";
import NextSceneButton from "@/components/sceneItems/NextSceneButton.vue";

@Component({
  components: {NextSceneButton}
})
export default class scene3 extends Vue {
  @Prop({required:true})role!: Roles

  sound = new Audio(require('@/assets/audio/gedicht.mp3'))

  mounted(){
    if(this.role==="screen"){
      this.sound.onended= ()=>{
        this.$store.dispatch("setSceneData",{showNext:true})
      }
      this.sound.play()

    }
  }

  beforeDestroy(){
    if(this.role==='screen'){
      this.sound.pause()
    }
  }
}


