



















import {Component, Vue, Watch} from "vue-property-decorator"
    import firebase from "firebase/app";
    import 'firebase/database'
    @Component({
        components: {}
    })
    export default class Start extends Vue {
      sessionId :string =''
      sessionExist:boolean = true
      selected:string = 'screen'

      pages=['screen','user','admin','speaker']


      async goToNextPage(){
        let exist=await this.getExists(this.sessionId)
        if(exist){
          this.$router.push(`/${this.sessionId}/${this.selected}`)
        }else{
          this.$store.dispatch("createSession",this.sessionId)
          this.$router.push(`/${this.sessionId}/screen`)
        }
      }

      @Watch('sessionId')
      async getExists(value:string){
        if(value!=''){
          let result = await firebase.database().ref(`sessions/${value}`).once("value")
          let exists=result.exists()
          console.log(exists)
          this.sessionExist=exists
          return exists
        }
      }
    }
