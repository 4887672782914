



































































import {Component, Prop, Vue} from "vue-property-decorator";
import {Roles} from "@/Interfaces/sessionInterface";
import {shuffle} from 'lodash'
import NextSceneButton from "@/components/sceneItems/NextSceneButton.vue";

export type QuestionStates = 'answer'|'question'|'askQuestion'
export type QuestionStateFunction<stateType> = (
    setQuestionState:(state:QuestionStates)=>void,
    setState:(newState:Partial<stateType>)=>void,
    addAudioFile:(file:HTMLAudioElement)=>HTMLAudioElement
) => void
export interface QuestionStateObject<valueType>{
  answer:valueType,
  askQuestion:valueType,
  question:valueType
}

export type QuestionStateAudioCallback=(setState:(QuestionStateFunction:QuestionStates)=>void)=>void
@Component({
  components: {NextSceneButton}
})
export default class Question extends Vue {
  @Prop({required:true})role!:Roles
  @Prop({required:true})askQuestion!:string
  @Prop({required:true})question!:string



  @Prop({default:(()=>{})})startAskQuestion!: QuestionStateFunction<this>
  @Prop({default:(()=>{})})startQuestion!:QuestionStateFunction<this>
  @Prop({default:(()=>{})})startAnswer!:QuestionStateFunction<this>


  @Prop({required:true})wrongAnswers!: string[]
  @Prop({required:true})correctAnswer!:string


  get showAnswer(){
    return this.$store.getters.getSceneData.showAnswer
  }

  answers:string[]=[]
  seconds=0
  answerSubmitted:boolean=false
  correctAnswerSubmitted:boolean=false
  valueAnswerSubmitted:string=''

  submitAnswer(answer:string){
    this.answerSubmitted=true
    this.valueAnswerSubmitted = answer
    if(answer === this.correctAnswer){
      console.log('hallo')
      this.correctAnswerSubmitted =true
      this.$store.dispatch("setUserGameData",{correctAnswers:this.$store.getters.userData.gameData.correctAnswers+1})
    }
  }

  setQuestionState(newState:QuestionStates){
    this.$store.dispatch("setSceneData",{questionState:newState})
  }

  setState(state:Partial<this>){
    Object.entries(state).forEach((value)=> {
      //@ts-ignore
      this[value[0]]=value[1]
    })
  }

  addAudioFile(file:HTMLAudioElement){
    this.audioFiles.push(file)
    return file
  }

  audioFiles:HTMLAudioElement[]=[]

  get state():QuestionStates{
    return this.$store.getters.getSceneData.questionState
  }

  unwatchStore:()=>void =()=>{}

  mounted(){
    this.answers.push(...this.wrongAnswers)
    this.answers.push(this.correctAnswer)
    this.answers=shuffle(this.answers)
    if(this.role ==='screen'){
      this.startAskQuestion(this.setQuestionState,this.setState,this.addAudioFile)
      this.unwatchStore=this.$store.watch((state1, getters) => getters.getSceneData,(value,oldValue) => {
        if(value.questionState!==oldValue.questionState){
          this.stopAllSound()
          const state: QuestionStates =value.questionState
          switch (state){
            case "answer":
              this.startAnswer(this.setQuestionState,this.setState,this.addAudioFile)
              break;
            case "question":
              this.startQuestion(this.setQuestionState,this.setState,this.addAudioFile)
              break;
            case "askQuestion":
              this.startAskQuestion(this.setQuestionState,this.setState,this.addAudioFile)
              break;
          }
        }
      })

    }
  }

  stopAllSound(){
    this.audioFiles.forEach(value => {
      value.pause()
      value.currentTime=0
    })
  }


  beforeDestroy(){
    if(this.role === "screen"){
      this.stopAllSound()
      this.unwatchStore()
    }
  }


}
