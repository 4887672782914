





























import {Component, Prop, Vue} from "vue-property-decorator";
import {Roles, User} from "@/Interfaces/sessionInterface";
import NextSceneButton from "@/components/sceneItems/NextSceneButton.vue";

@Component({
  components: {NextSceneButton}
})
export default class scene4 extends Vue {
  @Prop({required:true})role!: Roles


  kickUser(userId:string){
    this.$store.dispatch("kickUser",userId)
  }

  get userQrCode(){
    return `https://chart.googleapis.com/chart?cht=qr&chl=${window.location.origin +'/'+encodeURI(this.$store.state.sessionId)}/user&chs=500x500`
  }
  sound = new Audio(require('@/assets/audio/achtergrond_muziek.mp3'))



  get names() {
    if(this.$store.state.sessionData.users){
      return Object.values<User>(this.$store.state.sessionData.users).map(value=> value.name)
    }else{
      return []
    }
  }

  unwatchArray:(()=>void)[]=[]

  mounted(){
    this.sound.loop=true
    if(this.role==="screen"){
      this.sound.play()
    }

    if(this.role=="user"){
      this.unwatchArray.push(
          this.$store.watch((state, getters) => getters.userData,value => {
            if(value===undefined){
              this.$router.push('/')
            }
          })
      )
    }
  }


  beforeDestroy(){
    if(this.role==="screen"){
      this.sound.pause()
      this.unwatchArray.forEach(value => value())
    }
  }
}


