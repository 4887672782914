import { render, staticRenderFns } from "./Speaker.vue?vue&type=template&id=0cc13995&scoped=true&"
import script from "./Speaker.vue?vue&type=script&lang=ts&"
export * from "./Speaker.vue?vue&type=script&lang=ts&"
import style0 from "./Speaker.vue?vue&type=style&index=0&id=0cc13995&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0cc13995",
  null
  
)

export default component.exports