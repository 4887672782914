
































import {Component, Prop, Vue} from "vue-property-decorator";
import {Roles} from "@/Interfaces/sessionInterface";
import NextSceneButton from "@/components/sceneItems/NextSceneButton.vue";

@Component({
  components: {NextSceneButton}
})
export default class scene2 extends Vue {
  @Prop({required:true})role!: Roles

  sound = new Audio(require('@/assets/audio/sint_muted.mp3'))
  seconds=0.0
  mutedIcon=false
  timeInterval = 0

  mounted(){
    if(this.role==="screen"){
      this.timeInterval=setInterval(()=>{
        this.seconds=Math.round(this.seconds*10+1)/10
        console.log(this.seconds)
        if(this.seconds===10.7){
          this.mutedIcon=true
        }
        if(this.seconds===15.0){
          this.mutedIcon=false
        }
      },100)
      this.sound.onended= ()=>{
        clearInterval(this.timeInterval)
        this.$store.dispatch("setSceneData",{showNext:true})
      }
      this.sound.play()

    }
  }

  beforeDestroy(){
    if(this.role==="screen"){
      clearInterval(this.timeInterval)
      this.sound.pause()
    }
  }
}


