import scene0 from "@/components/scenes/scene0.vue";
import scene1 from "@/components/scenes/scene1.vue";
import scene2 from "@/components/scenes/scene2.vue";
import scene3 from "@/components/scenes/scene3.vue";
import scene4 from "@/components/scenes/scene4.vue";
import scene5 from "@/components/scenes/scene5.vue";
import scene6 from "@/components/scenes/scene6.vue";
import scene7 from "@/components/scenes/scene7.vue";
import scene8 from "@/components/scenes/scene8.vue";
import scene9 from "@/components/scenes/scene9.vue";
import scene10 from "@/components/scenes/scene10.vue";
import scene11 from "@/components/scenes/scene11.vue";
import scene12 from "@/components/scenes/scene12.vue";
import scene13 from "@/components/scenes/scene13.vue";

const scenes ={
    scene0,
    scene1,
    scene2,
    scene3,
    scene4,
    scene5,
    scene6,
    scene7,
    scene8,
    scene9,
    scene10,
    scene11,
    scene12,
    scene13
}
export default scenes
